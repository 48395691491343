// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sourceEntities {
  margin-top: 15px;
}
.sourceEntities .sourceEntity {
  color: #777 !important;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 4px 10px;
  border-radius: 8px;
  border: 0.5px solid rgba(255, 255, 255, 0.2);
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15);
}
.sourceEntities .sourceEntity div {
  font-size: 14px;
  line-height: 21px;
}
.sourceEntities .sourceEntity span {
  font-size: 14px;
  line-height: 21px;
}
.sourceEntities .sourceEntity .date {
  color: #8a8a8a;
  font-size: 12px;
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/SourceEntities.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;AAAF;AACE;EACI,sBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,4CAAA;EACA,8BAAA;EACA,+CAAA;AACN;AAAM;EACI,eAAA;EACA,iBAAA;AAEV;AAAM;EACI,eAAA;EACA,iBAAA;AAEV;AAAM;EACI,cAAA;EACA,eAAA;EACA,kBAAA;AAEV","sourcesContent":["\n.sourceEntities {\n  margin-top: 15px;\n  .sourceEntity {\n      color: #777 !important;\n      display: flex;\n      flex-direction: column;\n      margin-bottom: 10px;\n      padding: 4px 10px;\n      border-radius: 8px;\n      border: 0.5px solid rgba(255, 255, 255, 0.20);\n      background: rgba(0, 0, 0, 0.10);\n      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15);\n      div {\n          font-size: 14px;\n          line-height: 21px;\n      }\n      span {\n          font-size: 14px;\n          line-height: 21px;\n      }\n      .date {\n          color: #8a8a8a;\n          font-size: 12px;\n          font-style: italic;\n      }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
}
.modalHeader h2 {
  margin: 0;
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 100%;
}

.modalContent h2 {
  margin-top: 0;
}

.modalContent ul {
  list-style: none;
  padding-left: 0;
}

.modalContent li {
  margin: 8px 0;
}

.modalContent button {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/ModalWrapper.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;AACF;AAAE;EACE,SAAA;AAEJ;;AAEA;EACE,iBAAA;EACA,aAAA;EACA,kBAAA;EACA,yCAAA;EACA,YAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".modalOverlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.modalHeader {\n  display: flex;\n  justify-content: space-between;\n  h2 {\n    margin: 0;\n  }\n}\n\n.modalContent {\n  background: white;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  width: 400px;\n  max-width: 100%;\n}\n\n.modalContent h2 {\n  margin-top: 0;\n}\n\n.modalContent ul {\n  list-style: none;\n  padding-left: 0;\n}\n\n.modalContent li {\n  margin: 8px 0;\n}\n\n.modalContent button {\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.space-container {
  position: relative; /* Ensures parent bounds work */
  width: 100%;
  height: 100vh;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/Space/Space.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA,EAAA,+BAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;AACJ","sourcesContent":[".space-container {\n    position: relative; /* Ensures parent bounds work */\n    width: 100%;\n    height: 100vh;\n    overflow: hidden;\n    // background: #f0f0f0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// AppRouter.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ChatApp from './apps/ChatApp';
import LoginApp from './LoginApp'; // Standalone Chat Login
import NotFound from './NotFound';
import ProtectedRoute from './components/ProtectedRoute';
import System2 from './System2';
import PDFApp from './apps/PDFApp';
import BookkeeperApp from './apps/BookkeeperApp/BookkeeperApp';

const AppRouter = () => {
    return (
        <Routes>
            {/* Standalone components */}
            <Route
                path="/app/chat"
                element={
                    <ProtectedRoute>
                        <ChatApp onClose={() => {}} title="Chat" />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/app/pdf"
                element={
                    <ProtectedRoute>
                        <PDFApp onClose={() => {}} title="PDF" />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/app/bookkeeper"
                element={
                    <ProtectedRoute>
                        <BookkeeperApp onClose={() => {}} title="Bookkeeper" />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/system2"
                element={
                    <ProtectedRoute>
                        <System2 />
                    </ProtectedRoute>
                }
            />
            <Route path="/app/login" element={<LoginApp />} />

            {/* Catch-all for standalone apps */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AppRouter;

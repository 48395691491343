import React from 'react';
import { Rnd } from 'react-rnd';
import './Window.scss';

interface WindowProps {
    onFocus: () => void;
    title: string;
    onClose: () => void;
    children: React.ReactNode;
    position: { x: number; y: number };
    size: { width: number; height: number };
    onDragStop: (x: number, y: number) => void;
    onResizeStop: (width: number, height: number) => void;
}

const Window: React.FC<WindowProps> = ({
    onFocus,
    title,
    onClose,
    children,
    position,
    size,
    onDragStop,
    onResizeStop,
}) => {
    return (
        <Rnd
            minWidth={200}
            minHeight={150}
            bounds="parent"
            onMouseDown={onFocus}
            position={position}
            size={size}
            dragHandleClassName="window-header" // Specify the window-header as the draggable handle
            onDragStop={(e, d) => {
                // Update position when drag stops
                onDragStop(d.x, d.y);
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
                // Update size and position when resize stops
                onResizeStop(ref.offsetWidth, ref.offsetHeight);
                onDragStop(position.x, position.y);
            }}
            enableResizing={{
                top: true,
                right: true,
                bottom: true,
                left: true,
                topRight: true,
                bottomRight: true,
                bottomLeft: true,
                topLeft: true,
            }} // Allow resizing from all directions
        >
            <div className="window">
                <div className="window-header">
                    <span>{title}</span>
                    <button onClick={onClose}>X</button>
                </div>
                <div className="window-content">{children}</div>
            </div>
        </Rnd>
    );
};

export default Window;

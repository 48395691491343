import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import './DropZone.scss';

interface DropZoneProps {
    parentId: string | null;
    callback: (file: File) => void;
}

const DropZone: React.FC<DropZoneProps> = ({ callback }) => {
    const [isDragging, setIsDragging] = useState(false);

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            callback(file); // Handle the file upload in the parent component
        }
    }, [callback]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        onDragEnter: () => setIsDragging(true),
        onDragLeave: () => setIsDragging(false),
    });

    return (
        <div {...getRootProps()} className={`dropzone ${isDragging ? 'dragging' : ''}`}>
            <input {...getInputProps()} />
            <div className="dropzoneContent">
                {isDragActive ? (
                    <p>Drop the files here...</p>
                ) : (
                    <p>Drag & drop files here, or click to browse</p>
                )}
            </div>
        </div>
    );
};

export default DropZone;

import React, { useState, useRef } from "react";
import { MessageInput, MessageInputProps } from "@chatscope/chat-ui-kit-react";

interface CustomMessageInputProps extends MessageInputProps {
  as?: string | typeof MessageInput;
  sendMessage?: (message: string) => void;
}

interface SendButtonProps {
    onClick: () => void;
    style?: React.CSSProperties;
    children?: React.ReactNode;
  }

const CustomMessageInput = ({
  as: Component,
  sendMessage,
  ...rest
}: CustomMessageInputProps) => {
  const [messageInputValue, setMessageInputValue] = useState("");
  const inputRef = useRef(null);

  const handleSendClick = () => {
    if (messageInputValue.trim() !== "" && sendMessage) {
      sendMessage(messageInputValue);
      setMessageInputValue(""); // Clear the input after sending the message
    }
  };

  // Define the custom SendButton here
  const SendButton = ({ onClick, style, children }: SendButtonProps) => {
    return (
      <button onClick={onClick} style={style}>
        {children || "Send"}
      </button>
    );
  };

  return (
    <div className='customMessageInput'>
      <MessageInput
        ref={inputRef}
        onSend={handleSendClick} // Sends on Enter key press if needed
        onChange={(_: string, textContent: string) => {
          setMessageInputValue(textContent);
        }}
        value={messageInputValue}
        sendButton={false}
        attachButton={false}
        placeholder="Message Gal"
      />
      <div className='sendButton'>
        <SendButton
          onClick={handleSendClick}
        >
          ↗
        </SendButton>
      </div>
    </div>
  );
};

export default CustomMessageInput;

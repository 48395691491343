// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/apps/PDFApp.css */
.pdfApp {
  position: relative;
  height: 100%;
  width: auto;
  padding: 10px;
}

.toolbar {
  position: sticky;
  font-size: 12px;
  padding: 6px 6px;
  border-radius: 10px;
  border: 1px solid #ccc;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #f0f0f0;
  gap: 6px;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.toolbar button {
  padding: 4px 8px;
  border: none;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;
}

.toolbar button:hover {
  background-color: #e0e0e0;
}

.toolbar button.active {
  background-color: #d0d0d0;
}

.pdfViewer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.pdfViewer .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center; /* centers the pages horizontally */
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 40px;
}

.pageBreak {
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/apps/PDFApp.scss"],"names":[],"mappings":"AAAA,wBAAA;AACA;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,sBAAA;EACA,MAAA;EACA,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,yBAAA;EACA,QAAA;EACA,aAAA;EACA,wCAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;EACA,2DAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA,EAAA,mCAAA;EACA,kBAAA;EACA,cAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;EACA,6BAAA;EACA,kBAAA;AACF","sourcesContent":["/* src/apps/PDFApp.css */\n.pdfApp {\n  position: relative;\n  height: 100%;\n  width: auto;\n  padding: 10px;\n}\n\n.toolbar {\n  position: sticky;\n  font-size: 12px;\n  padding: 6px 6px;\n  border-radius: 10px;\n  border: 1px solid #ccc;\n  top: 0;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  background-color: #f0f0f0;\n  gap: 6px;\n  z-index: 1000;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  margin-bottom: 20px;\n}\n\n.toolbar button {\n  padding: 4px 8px;\n  border: none;\n  border-radius: 6px;\n  background-color: #fff;\n  cursor: pointer;\n  transition: background-color 0.2s ease, transform 0.1s ease;\n}\n\n.toolbar button:hover {\n  background-color: #e0e0e0;\n}\n\n.toolbar button.active {\n  background-color: #d0d0d0;\n}\n\n.pdfViewer {\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.pdfViewer .react-pdf__Document {\n  display: flex;\n  flex-direction: column;\n  align-items: center; /* centers the pages horizontally */\n  width: fit-content;\n  margin: 0 auto;\n  margin-bottom: 40px;\n}\n\n.pageBreak {\n  margin-bottom: 20px;\n  border-bottom: 1px solid #ccc;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

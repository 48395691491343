import React, { useEffect, useRef, useState } from 'react';
import './Space.scss'; // Import styling for the Space
import { AppRegistry } from '../../AppRegistry';
import Window from './Window';
import TaskBar from './TaskBar';
import ChatApp from '../../apps/ChatApp';
import SystemViewerApp from '../../apps/SystemViewerApp';
import ConsoleApp from '../../apps/ConsoleApp';

interface SpaceProps {
  id: string; // Unique identifier for each Space entity
  name: string; // Display name for the Space
}

interface WindowState {
  position: { x: number; y: number };
  size: { width: number; height: number };
}

const Space: React.FC<SpaceProps> = ({ id, name }) => {
  const [openWindows, setOpenWindows] = useState<string[]>([]);
  const [activeWindowId, setActiveWindowId] = useState<string | null>(null);
  const [windowStates, setWindowStates] = useState<{
    [key: string]: WindowState;
  }>({});
  const [windowIdToAppIdMap, setWindowIdToAppIdMap] = useState<{
    [key: string]: string;
  }>({});
  const spaceRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Listen for Shift + Left or Right Arrow
      if (event.shiftKey && event.key === 'ArrowLeft') {
        console.log('Shift + Left Arrow pressed');
        snapActiveWindow('left'); // Snap to the left
      } else if (event.shiftKey && event.key === 'ArrowRight') {
        console.log('Shift + Right Arrow pressed');
        snapActiveWindow('right'); // Snap to the right
      }
      // Use event.code for Shift + Number keys
      else if (event.shiftKey && event.code === 'Digit1') {
        console.log('Shift + 1 pressed');
        snapWindowToQuadrant('top-left');
      } else if (event.shiftKey && event.code === 'Digit2') {
        console.log('Shift + 2 pressed');
        snapWindowToQuadrant('top-right');
      } else if (event.shiftKey && event.code === 'Digit3') {
        console.log('Shift + 3 pressed');
        snapWindowToQuadrant('bottom-left');
      } else if (event.shiftKey && event.code === 'Digit4') {
        console.log('Shift + 4 pressed');
        snapWindowToQuadrant('bottom-right');
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listener on unmount
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [activeWindowId]);

  const snapWindowToQuadrant = (
    quadrant: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
  ) => {
    if (!activeWindowId) return;

    const spaceWidth = spaceRef.current?.offsetWidth || window.innerWidth;
    const spaceHeight = spaceRef.current?.offsetHeight || window.innerHeight;

    let newX = 0;
    let newY = 0;
    const newWidth = spaceWidth / 2; // Half the width
    const newHeight = spaceHeight / 2; // Half the height

    // Determine position based on quadrant
    switch (quadrant) {
      case 'top-left':
        newX = 0;
        newY = 0;
        break;
      case 'top-right':
        newX = spaceWidth / 2;
        newY = 0;
        break;
      case 'bottom-left':
        newX = 0;
        newY = spaceHeight / 2;
        break;
      case 'bottom-right':
        newX = spaceWidth / 2;
        newY = spaceHeight / 2;
        break;
    }

    console.log(`Snapping to quadrant: ${quadrant}`);
    console.log(`New position: x=${newX}, y=${newY}`);
    console.log(`New size: width=${newWidth}, height=${newHeight}`);

    // Update the window state
    setWindowStates(prevStates => ({
      ...prevStates,
      [activeWindowId]: {
        ...prevStates[activeWindowId],
        position: { x: newX, y: newY },
        size: { width: newWidth, height: newHeight },
      },
    }));
  };

  const snapActiveWindow = (direction: 'left' | 'right') => {
    if (!activeWindowId) {
      console.log('No active window');
      return;
    }

    const spaceWidth = spaceRef.current?.offsetWidth || window.innerWidth;
    const viewportHeight = window.innerHeight; // Use full viewport height

    console.log(`Snapping active window ${activeWindowId} to ${direction}`);
    console.log(
      `Space dimensions: width=${spaceWidth}, height=${viewportHeight}`
    );

    const newWidth = spaceWidth / 2; // Half the width of the space
    const newHeight = viewportHeight; // Full viewport height
    const newX = direction === 'left' ? 0 : spaceWidth / 2; // Left or right half of the screen
    const newY = 0;

    console.log(`New position: x=${newX}, y=0`);
    console.log(`New size: width=${newWidth}, height=${newHeight}`);

    setWindowStates(prevStates => ({
      ...prevStates,
      [activeWindowId]: {
        ...prevStates[activeWindowId],
        position: { x: newX, y: newY }, // Snap to top
        size: { width: newWidth, height: newHeight }, // Full height, half width
      },
    }));
  };

  // When opening an app, set the association:
  const openApp = (appId: string) => {
    const windowId = Date.now().toString();
    setOpenWindows(prev => [...prev, windowId]);

    // Set the mapping of windowId to appId
    setWindowIdToAppIdMap(prevMap => ({
      ...prevMap,
      [windowId]: appId,
    }));

    // Get the viewport dimensions
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Set window size
    const windowWidth = 600;
    const windowHeight = 500;

    // Calculate position to center the window in the viewport
    const centerX = (viewportWidth - windowWidth) / 2;
    const centerY = (viewportHeight - windowHeight) / 2;

    // Set the default position to be centered and size
    setWindowStates(prevStates => ({
      ...prevStates,
      [windowId]: {
        position: { x: centerX, y: centerY },
        size: { width: windowWidth, height: windowHeight },
      },
    }));
  };

  const closeApp = (windowId: string) => {
    setOpenWindows(prev => prev.filter(id => id !== windowId));
    setWindowStates(prevStates => {
      const newStates = { ...prevStates };
      delete newStates[windowId];
      return newStates;
    });
    if (activeWindowId === windowId) setActiveWindowId(null);
  };

  return (
    <div className="space-container">
      {/* <h1>{name}</h1> */}
      {openWindows.map(windowId => {
        const appId = windowIdToAppIdMap[windowId];
        const app = AppRegistry[appId];
        if (app && app.component) {
          const { component: AppComponent, title } = app;
          const windowState = windowStates[windowId];
          return (
            <Window
              key={windowId}
              title={title}
              onClose={() => closeApp(windowId)}
              position={windowState.position}
              size={windowState.size}
              onFocus={() => setActiveWindowId(windowId)}
              onDragStop={(x, y) => {
                // Update the position of the window
                setWindowStates(prevStates => ({
                  ...prevStates,
                  [windowId]: {
                    ...prevStates[windowId],
                    position: { x, y },
                  },
                }));
              }}
              onResizeStop={(width, height) => {
                // Update the size of the window
                setWindowStates(prevStates => ({
                  ...prevStates,
                  [windowId]: {
                    ...prevStates[windowId],
                    size: { width, height },
                  },
                }));
              }}
            >
              <AppComponent />
            </Window>
          );
        }
        return null;
      })}
      <TaskBar onOpenApp={openApp} />
    </div>
  );
};

export default Space;

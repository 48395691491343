import React from 'react';
import './TaskBar.scss';

interface TaskBarProps {
  onOpenApp: (appId: string) => void;
}

const TaskBar: React.FC<TaskBarProps> = ({ onOpenApp }) => {
  return (
    <div className="taskbar">
      <button className="appButton" onClick={() => onOpenApp('chat')}>
        Chat
      </button>
      <button className="appButton" onClick={() => onOpenApp('system')}>
        System Viewer
      </button>
      <button className="appButton" onClick={() => onOpenApp('console')}>
        Console
      </button>
      <button className="appButton" onClick={() => onOpenApp('browser')}>
        Browser
      </button>
      <button className="appButton" onClick={() => onOpenApp('pdf')}>
        PDF
      </button>
    </div>
  );
};

export default TaskBar;

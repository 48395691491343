import React from 'react';

const RespondingDots: React.FC = () => {
  return (
    <div className="responding-dots">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
};

export default RespondingDots;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.commandConsole {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.commandConsole .titleBar {
  height: 20px;
  background-color: #1e1e1e;
}

.xterm-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #1e1e1e; /* Ensure a dark background */
  overflow: hidden;
}

.xterm {
  display: block;
  background-color: #1e1e1e;
}

.xterm .xterm-viewport {
  background-color: #1e1e1e !important;
}

.xterm div {
  color: #49ee88;
  font-family: monospace, monospace !important;
}

.xterm textarea {
  autocomplete: off;
  spellcheck: false;
  inputmode: none;
}`, "",{"version":3,"sources":["webpack://./src/apps/ConsoleApp.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;AACJ;AAAI;EACI,YAAA;EACA,yBAAA;AAER;;AACA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,yBAAA,EAAA,6BAAA;EACA,gBAAA;AAEJ;;AACA;EACI,cAAA;EACA,yBAAA;AAEJ;;AAAA;EACI,oCAAA;AAGJ;;AAAA;EACI,cAAA;EACA,4CAAA;AAGJ;;AACA;EACI,iBAAA;EACA,iBAAA;EACA,eAAA;AAEJ","sourcesContent":[".commandConsole {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    .titleBar {\n        height: 20px;\n        background-color: #1e1e1e;\n    }\n}\n.xterm-container {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    background-color: #1e1e1e; /* Ensure a dark background */\n    overflow: hidden;\n}\n\n.xterm {\n    display: block;\n    background-color: #1e1e1e;\n}\n.xterm .xterm-viewport {\n    background-color: #1e1e1e !important;\n}\n\n.xterm div{\n    color: #49ee88;\n    font-family: monospace, monospace !important;\n}\n\n\n.xterm textarea {\n    autocomplete: off;\n    spellcheck: false;\n    inputmode: none;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

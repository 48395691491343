// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-institution-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}

.institution-list {
  list-style-type: none;
  padding: 0;
}

.institution-item {
  cursor: pointer;
  padding: 10px;
  margin: 5px 0;
  background: #e0e0e0;
  border-radius: 5px;
}

.institution-item:hover {
  background: #d0d0d0;
}`, "",{"version":3,"sources":["webpack://./src/apps/BookkeeperApp/SelectInstitutionModal.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,qBAAA;EACA,UAAA;AACF;;AAEA;EACE,eAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[".select-institution-modal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.modal-content {\n  background: #fff;\n  padding: 20px;\n  border-radius: 8px;\n  width: 300px;\n  text-align: center;\n}\n\n.institution-list {\n  list-style-type: none;\n  padding: 0;\n}\n\n.institution-item {\n  cursor: pointer;\n  padding: 10px;\n  margin: 5px 0;\n  background: #e0e0e0;\n  border-radius: 5px;\n}\n\n.institution-item:hover {\n  background: #d0d0d0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.window {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Shadow for native-like look */
  border: 1px solid #ccc;
  overflow: hidden;
  transition: all 0.3s ease; /* Smooth animation for movement and resizing */
}

.window-header {
  background-color: #f5f5f5;
  padding: 8px;
  cursor: move;
  -webkit-user-select: none;
          user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.window-content {
  flex-grow: 1;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Space/Window.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,0CAAA,EAAA,gCAAA;EACA,sBAAA;EACA,gBAAA;EACA,yBAAA,EAAA,+CAAA;AACF;;AAEA;EACE,yBAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;UAAA,iBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;;AACA;EACE,YAAA;EACA,cAAA;AAEF","sourcesContent":[".window {\n  display: flex;\n  flex-direction: column;  \n  width: 100%;\n  height: 100%;\n  background: #ffffff;\n  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Shadow for native-like look */\n  border: 1px solid #ccc;\n  overflow: hidden;\n  transition: all 0.3s ease; /* Smooth animation for movement and resizing */\n}\n\n.window-header {\n  background-color: #f5f5f5;\n  padding: 8px;\n  cursor: move;\n  user-select: none;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.window-content {\n  flex-grow: 1;\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

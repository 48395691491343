import React from 'react';
import { EmailType, AppleNoteType } from './types';
import './SourceEntities.scss';
import { format } from 'date-fns';

type EntityType = EmailType | AppleNoteType;

interface SourceEntitiesProps {
  sourceEntities: EntityType[];
}

const formatDate = (isoDate: string | null): string => {
  if (!isoDate) return 'unknown';

  // Example format: "Oct 17, 2023"
  return format(new Date(isoDate), 'MMM d, yyyy');
};

const SourceEntities = ({ sourceEntities }: SourceEntitiesProps) => {
  return (
    <>
      <div className="sourceEntities">
        {sourceEntities.map(entity => (
          <div key={entity._id} className="sourceEntity">
            {entity.entityType === 'Email' ? (
              // Rendering for Email entities
              <div className="sourceEmail">
                <div>
                  <strong>📧 {entity.subject}</strong>
                </div>
                <span>
                  <span>{entity.from} • </span>
                  <span className="date">{formatDate(entity.date)}</span>
                </span>
              </div>
            ) : entity.entityType === 'AppleNote' ? (
              // Rendering for AppleNote entities
              <div className="sourceAppleNote">
                <div>
                  <strong>📝 {entity.title}</strong>
                </div>
                <span>
                  <span>{entity.folder || 'unknown folder'} • </span>
                  <span className="date">{formatDate(entity.created_at)}</span>
                </span>
                <div>
                  {entity.plaintext
                    ? entity.plaintext
                        .trim()
                        .replace(/\s+/g, ' ')
                        .substring(0, 100)
                    : 'No content available...'}
                </div>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </>
  );
};

export default SourceEntities;

import React, { useState } from 'react';
import '../themes/default_main.scss';
import './ChatApp.scss';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
} from '@chatscope/chat-ui-kit-react';
import CustomMessageInput from '../CustomMessageInput';
import SourceEntities from '../SourceEntities';
import RespondingDots from '../RespondingDots';
import { EntityType, EmailType, AppleNoteType } from '../types';
import WindowWrapper from '../WindowWrapper';
import ModalSettings from '../components/ModalSettings';

interface MessageType {
  message: string;
  sentTime: string;
  sender: string;
  sourceEntities?: EntityType[];
}

interface SettingsType {
  filter: {
    entityTypes: string[]; // Correctly define entityType as an array of strings
  };
}

interface EntityRegistryItem {
  entityType: string;
}

const ChatApp = ({
  onClose,
  title,
}: {
  onClose: () => void;
  title: string;
}) => {
  const [messages, setMessages] = useState<MessageType[]>([
    {
      message: 'Hello! How can I assist you today?',
      sentTime: 'just now',
      sender: 'AI',
      sourceEntities: [],
    },
  ]);

  const [sourceEntities, setSourceEntities] = useState<EntityType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [authToken, setAuthToken] = useState<string | null>(
    localStorage.getItem('authToken')
  );

  const [settings, setSettings] = useState<SettingsType>({
    filter: {
      entityTypes: [],
    },
  });
  const [selectedEntities, setSelectedEntities] = useState<EntityType[]>([]);

  const usersEntityRegistry: EntityRegistryItem[] = [
    { entityType: 'Email' },
    { entityType: 'Photos' },
    { entityType: 'AppleNote' },
    { entityType: 'CalendarEvents' },
    { entityType: 'Contacts' },
  ];

  // Handle saving settings from the modal
  const handleSaveSettings = (selected: string[]) => {
    const updatedEntities = usersEntityRegistry.filter(entity =>
      selected.includes(entity.entityType)
    );
    const newSettings: SettingsType = {
      ...settings,
      filter: {
        ...settings.filter,
        entityTypes: updatedEntities.map(entity => entity.entityType),
      },
    };
    setSettings(newSettings);
    console.log(`Updated settings: ${JSON.stringify(newSettings, null, 2)}`);
  };

  const handleSend = async (message: string): Promise<void> => {
    setMessages((prevMessages: Array<MessageType>) => [
      ...prevMessages,
      { message: message, sentTime: 'just now', sender: 'User' },
    ]);

    setLoading(true); // waiting for backend to respond

    const settings = {
      filter: {
        entityType: selectedEntities.map(entity => entity.entityType),
      },
      // You can add more settings here in the future, e.g.:
      // display: { theme: 'dark', fontSize: 14 }
    };

    try {
      const response = await fetch(
        'https://humble-new-mammoth.ngrok-free.app/chat',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            message,
            settings,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log(data);

      // Map both Email and AppleNote entities to match the structure
      const entities: EntityType[] = (data.entities_used || [])
        .map((entity: any) => {
          if (entity.entityType === 'Email') {
            return {
              entityType: 'Email',
              gmail_id: entity.gmail_id,
              subject: entity.subject,
              from: entity.from,
              to: entity.to,
              date: entity.date,
              cleaned_plain_text: entity.cleaned_plain_text,
            } as EmailType;
          } else if (entity.entityType === 'AppleNote') {
            return {
              entityType: 'AppleNote',
              title: entity.title,
              plaintext: entity.plaintext,
              folder: entity.folder,
              created_at: entity.created_at,
              modified_at: entity.modified_at,
            } as AppleNoteType;
          }
          return null;
        })
        .filter(
          (entity: EntityType | null): entity is EntityType => entity !== null
        ); // Filter out null values

      console.log(entities);

      // update states with response data
      setMessages((prevMessages: Array<MessageType>) => [
        ...prevMessages,
        {
          message: data.answer,
          sentTime: 'just now',
          sender: 'AI',
          sourceEntities: entities,
        },
      ]);
      setSourceEntities(entities);
    } catch (error) {
      console.error('Error during fetch:', error);
      setMessages((prevMessages: Array<MessageType>) => [
        ...prevMessages,
        {
          message: 'Error: Unable to reach AI',
          sentTime: 'just now',
          sender: 'AI',
          sourceEntities: [],
        },
      ]);
    } finally {
      setLoading(false); // Hide animation when the response arrives
    }
  };

  return (
    // Conditionally wrap in WindowWrapper based on GUI vs standalone
    onClose ? (
      <WindowWrapper title={title} onClose={onClose}>
        <ChatAppContent
          messages={messages}
          handleSend={handleSend}
          loading={loading}
          sourceEntities={sourceEntities}
          settings={settings}
          handleSaveSettings={handleSaveSettings}
          usersEntityRegistry={usersEntityRegistry}
        />
      </WindowWrapper>
    ) : (
      <ChatAppContent
        messages={messages}
        handleSend={handleSend}
        loading={loading}
        sourceEntities={sourceEntities}
        settings={settings}
        handleSaveSettings={handleSaveSettings}
        usersEntityRegistry={usersEntityRegistry}
      />
    )
  );
};

interface ChatAppContentProps {
  messages: MessageType[];
  handleSend: (message: string) => void;
  loading: boolean;
  sourceEntities: EntityType[];
  settings: SettingsType;
  handleSaveSettings: (selected: string[]) => void;
  usersEntityRegistry: EntityRegistryItem[];
}

const ChatAppContent: React.FC<ChatAppContentProps> = ({
  messages,
  handleSend,
  loading,
  sourceEntities,
  settings,
  handleSaveSettings,
  usersEntityRegistry,
}) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const openSettings = () => setIsSettingsOpen(true);
  const closeSettings = () => setIsSettingsOpen(false);

  return (
    <div className="appMain">
      <div className="nav">
        <div className="aiName">Gal 001</div>
        <button onClick={openSettings} className="settingsButton">
          Settings
        </button>
      </div>
      <MainContainer>
        <ChatContainer>
          <MessageList>
            {messages.map((msg, index) => (
              <Message
                key={index}
                model={{
                  direction: msg.sender === 'User' ? 'outgoing' : 'incoming',
                  position: 'normal',
                  type: 'custom',
                }}
              >
                <Message.CustomContent>
                  <>
                    {msg.message}
                    {msg.sender !== 'User' &&
                      msg.sourceEntities &&
                      msg.sourceEntities.length > 0 && (
                        <div>
                          <SourceEntities
                            sourceEntities={msg.sourceEntities || []}
                          />
                        </div>
                      )}
                  </>
                </Message.CustomContent>
              </Message>
            ))}
            {loading && <RespondingDots />}
          </MessageList>
          <CustomMessageInput as={MessageInput} sendMessage={handleSend} />
        </ChatContainer>
      </MainContainer>

      {/* Use the new SettingsModal */}
      <ModalSettings
        entityTypes={usersEntityRegistry.map(entity => entity.entityType)}
        selectedEntityTypes={settings.filter.entityTypes}
        isOpen={isSettingsOpen}
        onClose={closeSettings}
        onSave={handleSaveSettings}
      />
    </div>
  );
};

export default ChatApp;

import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import WindowWrapper from '../WindowWrapper';
import './SystemViewerApp.scss';
import VerifiedBadge from '../components/icons/VerifiedBadge';

interface ModelInfo {
    name: string;
    description: string;
    type: string;
    status: string;
    runtime: string;
    device: string;
}

interface Entity {
    name: string;
    details?: string;
    children?: Entity[];
    status?: string;
    isVerified?: boolean;
    active?: boolean;
}

interface AccessibleData {
    entityType: string;
    count: number;
    active: boolean;
    isVerified: boolean; // Whether this pipeline was created by Bedrock
}

const SystemViewerApp = ({
    onClose,
    title,
}: {
    onClose: () => void;
    title: string;
}) => {
    // Mock user data until authentication is implemented
    const [authToken, setAuthToken] = useState<string | null>(
        localStorage.getItem('authToken')
    );
    const [selectedItem, setSelectedItem] = useState<any>(null); // For showing details in sidebar
    const [selectedRow, setSelectedRow] = useState<string | null>(null); // Track the selected row

    const [systemEntities, setSystemEntities] = useState<Entity[]>([
        {
            name: 'Users',
            children: [{ name: 'brian', details: 'admin' }],
        },
        {
            name: 'Models',
            children: [], // Empty until fetched
        },
        {
            name: 'Data',
            children: [
                {
                    name: 'Gmail',
                    details: '140,023',
                    active: true,
                    isVerified: true,
                },
                {
                    name: 'AppleNote',
                    details: '3,511',
                    active: true,
                    isVerified: true,
                },
                {
                    name: 'Slack',
                    details: '5,098',
                    active: false,
                    isVerified: false,
                },
            ],
        },
    ]);

    const [user, setUser] = useState({
        username: 'brian',
        role: 'admin',
        email: 'brian@example.com',
    });

    const [models, setModels] = useState<{ [key: string]: ModelInfo }>({});

    // Accessible Data Pipelines
    const [dataAccessible, setDataAccessible] = useState<AccessibleData[]>([
        { entityType: 'Gmail', count: 140023, active: true, isVerified: true },
        {
            entityType: 'AppleNote',
            count: 3511,
            active: true,
            isVerified: true,
        },
        { entityType: 'Slack', count: 5098, active: false, isVerified: false }, // Another example
    ]);

    useEffect(() => {
        if (!authToken) {
            console.error('No auth token found');
            return;
        }

        // Fetch models with JWT token in the Authorization header
        fetch('https://humble-new-mammoth.ngrok-free.app/models/available', {
            headers: {
                Authorization: `Bearer ${authToken}`, // Pass the token in the Authorization header
            },
        })
            .then(async response => {
                if (!response.ok) {
                    const errorDetails = await response.json();
                    console.error(
                        `Fetch error: ${response.status} - ${response.statusText}`
                    );
                    console.error('Error details:', errorDetails); // Log error details from response
                    throw new Error(
                        `Failed to fetch models: ${response.statusText}`
                    );
                }
                return response.json();
            })
            .then(data => {
                // Convert models into entities
                const modelEntities = Object.keys(data).map(modelKey => {
                    const model = data[modelKey];
                    return {
                        name: model.name,
                        details: model.runtime,
                        children: [], // Assuming models have no further children
                    };
                });

                // Update the systemEntities with fetched models
                setSystemEntities(prevEntities =>
                    prevEntities.map(entity =>
                        entity.name === 'Models'
                            ? { ...entity, children: modelEntities }
                            : entity
                    )
                );
            })
            .catch(err => {
                console.error('Error fetching models:', err.message);
            });
    }, [authToken]);

    // Handle item click to show details
    const handleItemClick = (item: any) => {
        setSelectedItem(item); // Show item details in sidebar
    };

    return (
        <WindowWrapper title={title} onClose={onClose}>
            <div className="systemViewer">
                <div className="mainContent">
                    <div className="nav">Name</div>
                    {systemEntities.map((entity, index) => (
                        <Row
                            key={index}
                            entity={entity}
                            onClick={handleItemClick}
                            selectedRow={selectedRow}
                            setSelectedRow={setSelectedRow}
                        />
                    ))}
                </div>
                {/* Sidebar for detailed information */}
                {selectedItem && (
                    <div className="sidebar">
                        <div className="header">Details</div>
                        <pre>{JSON.stringify(selectedItem, null, 2)}</pre>
                    </div>
                )}
            </div>
        </WindowWrapper>
    );
};

export default SystemViewerApp;

// Row Component (Generalized for all types)

interface RowProps {
    entity: Entity;
    onClick: (item: Entity) => void;
    selectedRow: string | null;
    setSelectedRow: (row: string) => void;
}

const Row = ({ entity, onClick, selectedRow, setSelectedRow }: RowProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        if (entity.children && entity.children.length > 0) {
            setIsExpanded(!isExpanded);
        }
    };

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const handleRowClick = () => {
        setSelectedRow(entity.name); // Set this row as selected
        onClick(entity); // Show the entity details in sidebar
    };

    return (
        <>
            <div
                className={`row ${selectedRow === entity.name ? 'selected' : ''} ${isHovered ? 'hovered' : ''}`}
                onClick={handleRowClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className="rowContent">
                    {entity.children && entity.children.length > 0 && (
                        <span className="expandCaret" onClick={toggleExpand}>
                            {isExpanded ? '▾' : '▸'}
                        </span>
                    )}
                    <span>{entity.name}</span>
                </div>
                {entity.details && (
                    <div className="rowDetails">
                        {entity.details}
                        {entity.isVerified && (
                            <VerifiedBadge className="verifiedBadge" />
                        )}
                        {entity.active && <span className="greenDot">•</span>}
                    </div>
                )}
            </div>

            {isExpanded && (
                <div className="childRows">
                    {entity.children?.map((child, index) => (
                        <Row
                            key={index}
                            entity={child}
                            onClick={onClick}
                            selectedRow={selectedRow}
                            setSelectedRow={setSelectedRow}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

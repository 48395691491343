import React, { useState } from 'react';
import './BrowserApp.scss';

const BrowserApp: React.FC = () => {
  const [url, setUrl] = useState<string>(
    'https://en.wikipedia.org/wiki/Main_Page'
  );

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };

  return (
    <div className="browser-app">
      <div className="browser-header">
        <input
          type="text"
          value={url}
          onChange={handleUrlChange}
          placeholder="Enter a URL..."
        />
        <button onClick={() => window.open(url, '_blank')}>Go</button>
      </div>
      <div className="browser-content">
        <iframe src={url} title="Browser" width="100%" height="100%" />
      </div>
    </div>
  );
};

export default BrowserApp;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accounts-page {
  padding: 20px;
}

.add-account-btn {
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
}

.account-list {
  list-style-type: none;
  padding: 0;
}

.account-item {
  background: #f4f4f4;
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/apps/BookkeeperApp/Accounts.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,eAAA;EACA,aAAA;EACA,eAAA;AACF;;AAEA;EACE,qBAAA;EACA,UAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;EACA,cAAA;EACA,kBAAA;AACF","sourcesContent":[".accounts-page {\n  padding: 20px;\n}\n\n.add-account-btn {\n  font-size: 24px;\n  padding: 10px;\n  cursor: pointer;\n}\n\n.account-list {\n  list-style-type: none;\n  padding: 0;\n}\n\n.account-item {\n  background: #f4f4f4;\n  padding: 10px;\n  margin: 10px 0;\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

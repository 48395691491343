import React, { useEffect, useState } from 'react';
import { AppRegistry } from './AppRegistry'; // Registry for available apps
import './System.scss'; // Style for the desktop and app windows
import LoginGUI from './LoginGUI';
import { useNavigate, useLocation } from 'react-router-dom';
// import Egui from './Egui'; // Egui Component
import Space from './components/Space/Space';

const System2 = () => {
  const [bootComplete, setBootComplete] = useState(false);
  const [checkingAuth, setCheckingAuth] = useState(true);
  const [authToken, setAuthToken] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  // On component mount, check for the auth token and determine if boot is complete
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setAuthToken(token);
      setBootComplete(true); // Skip boot if authenticated
    }
    setCheckingAuth(false); // Stop the loading state once the check is done
  }, []);

  // Check if the current path is for standalone apps
  const isStandaloneApp = location.pathname.startsWith('/app');
  if (isStandaloneApp) {
    return null;
  }

  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Remove token from local storage
    setAuthToken(null); // Clear auth token from state
    window.location.reload(); // Reload the page
  };

  if (checkingAuth) {
    return <div className="loading">Loading...</div>; // You can replace this with a spinner or custom component
  }

  return (
    <>
      {!bootComplete ? (
        <LoginGUI onFinish={() => setBootComplete(true)} />
      ) : (
        <div className="desktop">
          {/* Egui Window Manager */}
          {/* <Egui /> */}
          <Space id="space1" name="space1" />
          {/* Logout Button */}
          <button onClick={handleLogout} className="logoutButton">Log Out</button>
        </div>
      )}
    </>
  );
};

export default System2;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.browser-app {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.browser-header {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.browser-header input {
  flex-grow: 1;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 8px;
  outline: none;
}

.browser-header button {
  padding: 8px 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.browser-header button:hover {
  background-color: #45a049;
}

.browser-content {
  flex-grow: 1;
  overflow: hidden;
}

.browser-content iframe {
  border: none;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/apps/BrowserApp.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,6BAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EACA,eAAA;EACA,sBAAA;EACA,kBAAA;EACA,iBAAA;EACA,aAAA;AACF;;AAEA;EACE,iBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,iCAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,YAAA;AACF","sourcesContent":[".browser-app {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n}\n\n.browser-header {\n  display: flex;\n  align-items: center;\n  background-color: #f5f5f5;\n  padding: 8px;\n  border-bottom: 1px solid #ccc;\n}\n\n.browser-header input {\n  flex-grow: 1;\n  padding: 8px;\n  font-size: 16px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  margin-right: 8px;\n  outline: none;\n}\n\n.browser-header button {\n  padding: 8px 12px;\n  background-color: #4CAF50;\n  color: white;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.2s;\n}\n\n.browser-header button:hover {\n  background-color: #45a049;\n}\n\n.browser-content {\n  flex-grow: 1;\n  overflow: hidden;\n}\n\n.browser-content iframe {\n  border: none;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bookkeeper-table {
  width: 100%;
  border-collapse: collapse;
}
.bookkeeper-table th, .bookkeeper-table td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}
.bookkeeper-table input {
  width: 100%;
}

.bookkeeper-toolbar button.active {
  font-weight: bold;
  color: #007bff;
  border-bottom: 2px solid #007bff;
}

.transaction-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  display: flex;
  flex-direction: column;
}
.modal-content h2 {
  margin-top: 0;
}
.modal-content label {
  margin: 10px 0 5px;
}
.modal-content input {
  margin-bottom: 10px;
}
.modal-content button {
  margin: 5px 0;
  padding: 8px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/apps/BookkeeperApp/BookkeeperApp.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,yBAAA;AACF;AACE;EACE,YAAA;EACA,gBAAA;EACA,sBAAA;AACJ;AAEE;EACE,WAAA;AAAJ;;AAIA;EACE,iBAAA;EACA,cAAA;EACA,gCAAA;AADF;;AAKA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAFF;;AAKA;EACE,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;AAFF;AAIE;EACE,aAAA;AAFJ;AAKE;EACE,kBAAA;AAHJ;AAME;EACE,mBAAA;AAJJ;AAOE;EACE,aAAA;EACA,YAAA;EACA,eAAA;AALJ","sourcesContent":[".bookkeeper-table {\n  width: 100%;\n  border-collapse: collapse;\n\n  th, td {\n    padding: 8px;\n    text-align: left;\n    border: 1px solid #ddd;\n  }\n\n  input {\n    width: 100%;\n  }\n}\n\n.bookkeeper-toolbar button.active {\n  font-weight: bold;\n  color: #007bff;\n  border-bottom: 2px solid #007bff;\n}\n\n\n.transaction-modal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.modal-content {\n  background: #fff;\n  padding: 20px;\n  border-radius: 8px;\n  width: 300px;\n  display: flex;\n  flex-direction: column;\n\n  h2 {\n    margin-top: 0;\n  }\n\n  label {\n    margin: 10px 0 5px;\n  }\n\n  input {\n    margin-bottom: 10px;\n  }\n\n  button {\n    margin: 5px 0;\n    padding: 8px;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

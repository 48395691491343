import BrowserApp from './apps/BrowserApp';
import ChatApp from './apps/ChatApp';
import ConsoleApp from './apps/ConsoleApp';
import PDFApp from './apps/PDFApp';
import SystemViewerApp from './apps/SystemViewerApp';

export const AppRegistry: {
  [key: string]: { component: React.ComponentType<any>; title: string };
} = {
  chat: { component: ChatApp, title: 'Chat' },
  system: { component: SystemViewerApp, title: 'System Viewer' },
  console: { component: ConsoleApp, title: 'Console' },
  browser: { component: BrowserApp, title: 'Browser' },
  pdf: { component: PDFApp, title: 'PDF' },
};
